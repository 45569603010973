<template>
  <div class="flix-row" v-if="data !== false">
    <div class="flix-col-md-12">
      <div class="flix-form-group" v-if="format === 'privacy' || format === 'imprint'">
        <div class="flix-alert flix-alert-info">{{ $t('message.imprintUse') }}</div>
      </div>
      <div class="flix-form-group" v-if="format === 'privacy'">
        <a href="#" class="flix-btn flix-btn-xs flix-btn-default" @click.prevent="setDefault()">{{ $t('message.generate', {name: $t('message.default', { name: $t('message.privacy') })}) }} ({{ $tc('message.presets', 1) }} {{ $tc('message.textblock', 1) }})</a>
      </div>
      <div class="flix-form-group" v-if="format === 'privacy'">
        <flixIcon id="info-sign" /> {{ $t('message.privacyHint') }}
      </div>
      <div class="flix-form-group">
        <label class="flix-html-label">{{ $t('message.' + format) }}</label>
        <ckeditor :editor="editor" v-model="data" :config="editorConfig"></ckeditor>
      </div>
      <div class="flix-form-group">
        <saveBtn :onClick="function () { saveData() }">
          <template v-slot:text>
            {{ $t('message.save', {name: $t('message.' + format)}) }}
          </template>
        </saveBtn>
      </div>
      <div class="flix-form-group" v-if="save">
        <div class="flix-alert flix-alert-success">{{ $t('message.successfullSaved') }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/de'
import defaultPrivacy from './defaultPrivacy'

export default {
  components: {},
  data () {
    return {
      defaultPrivacy: defaultPrivacy,
      format: 'privacy',
      variables: this.$getUserVariables(),
      data: false,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: ['heading', '|', 'bold', 'italic', '|', 'link', '|', 'bulletedList', 'numberedList'],
        language: 'de'
      },
      save: false
    }
  },
  methods: {
    setDefault () {
      this.data = this.defaultPrivacy + this.data
    },
    getData () {
      this.$flix_post({
        url: 'user/get_imprint',
        data: {
          user: this.variables.user.md5_id
        },
        callback: function (ret) {
          this.data = ret.data[1][this.format]
        }.bind(this)
      })
    },
    saveData () {
      var save = { user: this.variables.user.md5_id, data: {} }
      save.data[this.format] = this.data
      save.data.email = this.variables.user.email
      // window.console.log(save)
      this.$flix_post({
        data: save,
        url: 'user/update',
        callback: function (ret) {
          this.save = true
          setTimeout(function () {
            this.save = false
          }.bind(this), 3000)
        }.bind(this)
      })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>
<style lang="sass" scoped>
</style>
