const defaultPrivacy = [
  '<h1>Datenschutz-Erklärung</h1>',
  '',
  'Stand: ' + new Date().getDate() + '.' + (new Date().getMonth() + 1) + '.' + new Date().getFullYear(),
  '',
  'Für unseren Online-Termin-Kalender setzen wir auf dieser Webseite das Termintool Bookingflix.com ein. Dies ist ein Online-Termine-Tool zur automatischen Online-Termin-Vereinbarung.',
  'Bookinflix.com ist ein Dienst der Firma Einzelunternehmung Marcus Hanke, Ardeystraße 211 in 58453 Witten, Deutschland.',
  '',
  'E-Mail: <a href="mailto: support@bookingflix.com">support@bookingflix.com</a>',
  'Telefon: 02302-4007736',
  '',
  'Weitere Informationen zu den Nutzungsbedingungen von Bookingflix und den datenschutzrechtlichen Regelungen finden Sie unter: <a href="https://web.bookingflix.com/privacy" target="_blank">https://web.bookingflix.com/privacy</a>',
  '',
  'Bei der Nutzung von Bookingflix werden folgende personenbezogene Daten erhoben:',
  'Siehe Abfragefelder des Bookingflix-Online-Termin-Kalenders auf unserer Website oder alternativ nachfolgende personenbezogenen Daten:',
  '',
  '(... Bitte nachtragen ...)',
  '',
  'Mit der Nutzung von Bookingflix werden Daten an Server von Bookingflix übermittelt, die außerhalb unserer Kontrolle liegen. Ein Vertrag zur Auftragsverarbeitung mit Bookingflix liegt vor, in dem wir Bookingflix verpflichten, die Daten unserer Kunden zu schützen und sie nicht an Dritte weiterzugeben. Weitere Informationen zum Datenschutz von Bookingflix finden Sie hier: <a href="https://web.bookingflix.com/privacy" target="_blank">https://web.bookingflix.com/privacy</a>',
  '',
  'Soweit die Verarbeitung Ihrer Daten nicht von der Einwilligung umfasst ist (insb. Log-Dateien), steht Ihnen ein Widerspruchsrecht zu. Ihren Widerspruch können Sie uns jederzeit zusenden bzw. mitteilen (Email und/oder postalisch).',
  '',
  'Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Die Standard-Löschfristen betragen bei Bookingflix.com aktuell einen Monat.',
  '',
  'Die Bereitstellung der personenbezogenen Daten ist weder gesetzlich noch vertraglich vorgeschrieben und auch nicht für einen Vertragsabschluss erforderlich.',
  '',
  'Sie sind auch nicht verpflichtet, die personenbezogenen Daten bereitzustellen. Die Nichtbereitstellung hätte jedoch zur Folge, dass eine Terminabsprache über Bookingflix.com nicht erfolgen könnte.'
].join('<br />')

export default defaultPrivacy
